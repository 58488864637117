import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { Box } from "../core"
import { GuideCard } from "./guide-card"

export function GuideList({ guides }) {
  return (
    <ListWrapper>
      <GuideCard guide={guides[0]} />
      <Divider />
      <GuideCard guide={guides[1]} />
      {/* <Sidebar> */}
      {/* <Divider /> */}
      {/* <GuideCard guide={guides[2]} small /> */}
      {/* </Sidebar> */}
    </ListWrapper>
  )
}

const ListWrapper = styled(Box).attrs(() => ({
  marginBottom: ["32px", "64px"],
}))`
  @media (min-width: ${themeGet("breakpoints.sm")}) {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: auto 1px auto;
  }
`

const Divider = styled(Box).attrs(() => ({}))`
  display: none;

  @media (min-width: ${themeGet("breakpoints.sm")}) {
    display: block;
    background: ${themeGet("colors.grayRGBA._300")};
  }
`

const Sidebar = styled(Box).attrs(() => ({}))`
  @media (min-width: ${themeGet("breakpoints.sm")}) {
    display: grid;
    grid-gap: 32px;
    grid-template-rows: auto 1px auto;
  }
`
