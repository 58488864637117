import { themeGet } from "@styled-system/theme-get"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Box } from "./core"
import { Lead } from "./core/typography"
import { Subscribe } from "./pivot/subscribe"

export function Hero() {
  return (
    <Box mt={[0]} py={[2]}>
      <InnerWrapper>
        <Box>
          <Box as="h1" lineHeight={0}>
            Product reviews for your health.
          </Box>
          <Box mt={6}>
            <Lead>
              Lemon Lab rigorously tests and reports on products to bring more transparency to health and wellness. Our
              reviews are also powered by contributors, because personal product recommendations are the most powerful
              ones.
            </Lead>
            <Link to="/about">Learn More -></Link>
          </Box>
        </Box>
        <SubscribeWrapper>
          <Box>
            <Box textAlign="center" lineHeight={1} mb={6} mt={[0, 0, -4]}>
              <Box fontSize={4} fontWeight={3}>
                Never miss a review.
              </Box>
              <Box fontSize={2} mt={2}>
                Get our weekly brief of science-backed snippets.
              </Box>
            </Box>
            <Subscribe />
          </Box>
        </SubscribeWrapper>
      </InnerWrapper>
    </Box>
  )
}

const InnerWrapper = styled(Box)`
  @media (min-width: ${themeGet("breakpoints.md")}) {
    display: flex;
    align-items: center;
  }
`

const SubscribeWrapper = styled(Box).attrs(() => ({
  backgroundColor: "accent.default",
  p: [8, 12, 16],
  mt: [16, 16, 0],
  ml: [0, 0, 60, 120],
}))`
  border: 2px solid black;

  @media (min-width: ${themeGet("breakpoints.md")}) {
    flex-shrink: 0;
    position: relative;
    width: 450px;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: ${themeGet("breakpoints.lg")}) {
    width: 550px;
    height: 550px;
  }
`
