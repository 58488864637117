import { graphql } from "gatsby"
import React from "react"
import { SectionHeader } from "../components/content/section-header"
import { GuideList } from "../components/guide/guide-list"
import { Hero } from "../components/hero"
import { Layout } from "../components/layout"
import { ProductCarousel } from "../components/product/product-carousel"
import { SEO } from "../components/seo"
import { filterOutDocsWithoutSlugs, mapEdgesToNodes } from "../lib/helpers"

export const pageQuery = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    guides: allSanityGuide(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          _updatedAt
          publishedAt(formatString: "MMMM, YYYY")
          title
          short_description
          slug {
            current
          }
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }

    reviewedProducts: allSanityProduct(
      sort: { order: DESC, fields: _updatedAt }
      filter: { status: { eq: "reviewed" } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          short_description
          status
          colorPrimary
          colorSecondary
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }

    inReviewProducts: allSanityProduct(
      sort: { order: DESC, fields: _updatedAt }
      filter: { status: { eq: "inReview" } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          short_description
          status
          colorPrimary
          colorSecondary
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`

export default function IndexPage({ data: { site, guides, reviewedProducts, inReviewProducts, notes } }) {
  const { description, keywords } = site

  const guideNodes = mapEdgesToNodes(guides).filter(filterOutDocsWithoutSlugs)
  const reviewedProductNodes = mapEdgesToNodes(reviewedProducts).filter(filterOutDocsWithoutSlugs)
  const inReviewProductNodes = mapEdgesToNodes(inReviewProducts).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout wide>
      <SEO title={"More tests, fewer lemons"} description={description} keywords={keywords} />
      <Hero />
      <SectionHeader title="Reviewed" link={"/products"} linkLabel="View All Products →" />
      <ProductCarousel products={reviewedProductNodes} />
      <SectionHeader title="Guides" />
      <GuideList guides={guideNodes} />
      <SectionHeader title="Upcoming" link={"/products"} linkLabel="View All Products →" />
      <ProductCarousel products={inReviewProductNodes} />
    </Layout>
  )
}
