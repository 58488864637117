import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { buildImageObj } from "../../lib/helpers"
import { imageUrlFor } from "../../lib/image-url"
import { Box } from "../core"

export function GuideCard({ guide, small }) {
  const { title, short_description, publishedAt, _updatedAt, slug, mainImage } = guide

  return (
    <a href={"/guides/" + slug.current}>
      <CardWrapper>
        <Cover
          style={{ width: "100%", height: "auto" }}
          src={imageUrlFor(buildImageObj(mainImage)).width(800).height(400).fit("max").ignoreImageParams().url()}
          marginBottom={[small ? "16px" : "24px"]}
        />
        <Title fontSize={[4, small ? 3 : 5]}>{title}</Title>
        <Subtitle
          fontSize={[2, small ? 2 : 3]}
          lineHeight={[1, small ? 1 : 2]}
          // display={["block", small ? "none" : "block"]}
        >
          {short_description}
        </Subtitle>
        <Box display="flex" alignItems="center" mt={["8px"]}>
          <GuideTag>GUIDE</GuideTag>
          <Date datetime={_updatedAt}>{publishedAt}</Date>
        </Box>
      </CardWrapper>
    </a>
  )
}

const CardWrapper = styled(Box).attrs(() => ({
  marginBottom: ["32px", "0"],
}))``

const GuideTag = styled(Box).attrs(() => ({
  mr: ["8px"],
  paddingX: ["4px"],
  paddingY: ["2px"],
  fontSize: [0],
  fontWeight: [3],
  lineHeight: 0,
  letterSpacing: 1,
  color: "text.secondary",
}))`
  border: 2px solid ${themeGet("colors.text.secondary")};
`

const Cover = styled(Box).attrs(() => ({
  as: "img",
}))``

const Title = styled(Box).attrs(() => ({
  as: "h2",
  lineHeight: 1,
}))`
  font-weight: 600;
`

const Subtitle = styled(Box).attrs(() => ({
  as: "h3",
  marginTop: ["2px", "4px"],
}))`
  font-weight: 400;
  color: ${themeGet("colors.text.secondary")};
`

const Date = styled(Box).attrs(() => ({
  as: "time",
  fontSize: 1,
  lineHeight: 1,
}))`
  font-weight: 400;
  color: ${themeGet("colors.text.secondary")};
`
