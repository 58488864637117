import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { getColor } from "../../lib/colors"
import { buildImageObj } from "../../lib/helpers"
import { imageUrlFor } from "../../lib/image-url"
import { Box } from "../core"

export function ProductCard({ product }) {
  const { title, short_description, status, colorPrimary, colorSecondary, slug, mainImage } = product

  const isReviewed = status === "reviewed"

  const Card = (
    <CardWrapper>
      <TextWrapper>
        <div>
          <Title>{title}</Title>
          <Subtitle>{short_description}</Subtitle>
        </div>
        {isReviewed ? (
          <CTA>
            Dig deeper <span>→</span>
          </CTA>
        ) : (
          <CTA>Coming Soon</CTA>
        )}
      </TextWrapper>
      <ImageWrapper backgroundColor={getColor(colorPrimary).background}>
        <ImageBackground backgroundColor={getColor(colorSecondary).background} />
        {mainImage && mainImage.asset && (
          <StyledImg
            src={imageUrlFor(buildImageObj(mainImage)).width(400).height(400).fit("max").ignoreImageParams().url()}
          />
        )}
      </ImageWrapper>
    </CardWrapper>
  )

  if (isReviewed) {
    return <a href={"/products/" + slug.current}>{Card}</a>
  }

  return Card
}

const CardWrapper = styled(Box).attrs(() => ({
  as: "li",
  height: ["160px", "220px"],
}))`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 40%;
  border: 2px solid ${themeGet("colors.black")};
  @media (min-width: ${themeGet("breakpoints.sm")}) {
    grid-template-columns: 1fr minmax(200px, 35%);
  }
`

const ImageWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  transition: right 300ms ease-in-out;

  ${CardWrapper}:hover & {
    right: 0%;
  }
`

const StyledImg = styled(Box).attrs(() => ({
  as: "img",
  width: ["75%", "85%"],
  height: "auto",
  zIndex: 1,
}))``

const TextWrapper = styled(Box).attrs(() => ({
  padding: ["16px", "28px"],
  paddingRight: ["16px", "28px"],
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px solid;
`

const Title = styled(Box).attrs(() => ({
  as: "h2",
  fontSize: [2, 5],
}))`
  font-weight: 500;
`

const Subtitle = styled(Box).attrs(() => ({
  as: "h3",
  fontSize: [0, 2],
  lineHeight: [2],
  fontWeight: [0],
  mt: ["8px"],
}))`
  @media (max-width: ${themeGet("breakpoints.sm")}) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
`

const CTA = styled(Box).attrs(() => ({
  fontSize: [1, 2],
}))`
  font-weight: 500;

  span {
    position: relative;
    left: 0;
    transition: left 300ms ease-in-out;

    ${CardWrapper}:hover & {
      left: 4px;
    }
  }
`
