import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { Box } from "../core"
import { SmartLink } from "../smartlink"

export function SectionHeader({ title, link, linkLabel }) {
  return (
    <SectionHeaderWrapper>
      <Title>{title}</Title>
      <SmartLink label={linkLabel} path={link} />
    </SectionHeaderWrapper>
  )
}

const SectionHeaderWrapper = styled(Box).attrs(() => ({
  marginTop: ["64px"],
  py: ["28px"],
  fontSize: 2,
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${themeGet("colors.grayRGBA._300")};
`

const Title = styled(Box).attrs(() => ({
  as: "h4",
  fontSize: 3,
}))``
