import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { Box } from "../core"
import { ProductCard } from "./product-card"

export function ProductCarousel({ products }) {
  return (
    <Box position="relative">
      <EdgeMasks />
      <ListWrapper>
        {products.map((product) => (
          <ProductCard product={product} key={product.id} />
        ))}
      </ListWrapper>
    </Box>
  )
}

const ListWrapper = styled(Box).attrs(() => ({
  as: "ul",
  mx: ["-24px", "-24px", "-32px", "-64px", 0],
  px: ["24px", "24px", "32px", "64px", 0],
}))`
  position: relative;
  display: grid;
  grid-gap: 24px;
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(80vw, 1fr));
  grid-auto-columns: minmax(80vw, 1fr);

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 24px;

  @media (min-width: ${themeGet("breakpoints.sm")}) {
    scroll-padding: 32px;
  }

  @media (min-width: ${themeGet("breakpoints.sm")}) {
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-auto-columns: minmax(500px, 1fr);

    scroll-padding: 64px;
  }

  @media (min-width: ${themeGet("breakpoints.sm")}) {
    scroll-padding: 200px;
  }

  & > * {
    scroll-snap-align: start;
  }
`

const EdgeMasks = styled(Box).attrs(() => ({
  display: ["none", "none", "none", "none", "block"],
}))`
  &:before,
  &:after {
    z-index: 2;
    content: " ";
    display: block;
    position: absolute;
    top: -16px;
    bottom: -16px;
  }

  &:before {
    width: 40px;
    left: -200px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  &:after {
    width: 1px;
    right: -200px;
    right: 0px;
    background: ${themeGet("colors.grayRGBA._300")};
  }
`
